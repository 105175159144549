import React from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import * as dateFns from 'date-fns';

import headerBg from 'images/element/blog/header-bg.png';
import headerText from 'images/element/blog/header-text.svg';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import Dimension from 'components/dimension';

const ComponentText = {
  LATEST_POST: 'latest post',
};

const Blog = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  let blogs = [];
  if (data.types.group && data.types.group.length > 0) {
    blogs = data.types.group[0].edges;
  }

  const date = dateFns.parse(frontmatter.date, 'yyyy-MM-dd', new Date());

  return (
    <Layout
      renderContent={() => {
        return (
          <>
            <SEO title="Blog" />
            <div
              className="flex flex-col items-center w-full bg-white max-w-screen-xl"
              style={{
                marginTop: Dimension.NAVBAR_HEIGHT,
              }}
            >
              <div className="relative">
                <img
                  src={headerBg}
                  alt="header background"
                  className="object-cover w-full h-auto"
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      src={headerText}
                      alt="Blog"
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start w-full py-10 lg:flex-row max-w-screen-lg">
                <div className="flex flex-col w-full px-4 markdown">
                  <div>
                    <Img
                      alt="Featured"
                      fluid={frontmatter.featuredImage.childImageSharp.fluid}
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="relative mt-6">
                    <div className="relative inline-flex">
                      <div
                        className="text-2xl font-bold lg:text-3xl leading-10"
                        style={{ zIndex: 1 }}
                      >
                        {frontmatter.title}
                      </div>
                      <div
                        className="absolute bottom-0 w-full h-1"
                        style={{ background: '#D2A677' }}
                      />
                    </div>
                  </div>

                  <div className="text-sm text-gray-600 ">
                    {dateFns.format(date, 'PP')}
                  </div>

                  <div className="mt-6">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                </div>

                <div className="flex flex-col w-full px-4 mt-8 lg:mt-0 lg:w-1/3">
                  <div className="mb-2 text-xl font-semibold uppercase lg:text-2xl text-secondary">
                    {ComponentText.LATEST_POST}
                  </div>

                  {blogs.map(sv => {
                    return (
                      <div
                        key={sv.node.frontmatter.path}
                        className="w-full border border-gray-100"
                      >
                        <button
                          type="button"
                          className="w-full p-2 text-sm text-left bg-gray-200 hover:bg-gray-300 overflow-hidden"
                          onClick={() => navigate(sv.node.frontmatter.path)}
                        >
                          <div className="inline-flex items-center w-full">
                            <Img
                              alt="Featured"
                              fluid={
                                sv.node.frontmatter.featuredImage
                                  .childImageSharp.fluid
                              }
                              className="flex-shrink-0 w-12 h-12"
                            />

                            <p className="ml-2 truncate ...">
                              {sv.node.frontmatter.title}
                            </p>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default Blog;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    types: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 100
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      group(field: frontmatter___type) {
        fieldValue
        edges {
          node {
            snippet
            html
            frontmatter {
              path
              title
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 1300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
